import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import Firestore instance from your firebase configuration
import { useUserLevel, AdminLevel } from '../../admin';

interface User {
  id: string;
  name: string;
  email: string;
  groupId: string | null;
}

interface Group {
  id: string;
  groupId: string;
  recoveryPlan: string;
  Admin: string[];
  Members: string[];
}

interface ModifyGroupProps {
  onClose: () => void;
  id: string;
}

const ModifyGroup: React.FC<ModifyGroupProps> = ({ onClose, id }) => {
  const { currentUserLevel } = useUserLevel();
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [groupName, setGroupName] = useState('');
  const [recoveryPlan, setRecoveryPlan] = useState('');
  const [recoveryPlans, setRecoveryPlans] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [filteredAdmins, setFilteredAdmins] = useState<User[]>([]);
  const [filteredMembers, setFilteredMembers] = useState<User[]>([]);
  const [selectedAdmins, setSelectedAdmins] = useState<Set<string>>(new Set());
  const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set());
  const [adminSearchQuery, setAdminSearchQuery] = useState<string>('');
  const [memberSearchQuery, setMemberSearchQuery] = useState<string>('');
  const [groups, setGroups] = useState<Group[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersData: User[] = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        email: doc.data().email,
        groupId: doc.data().groupId || null,
      }));

      setUsers(usersData);
    };

    const fetchRecoveryPlans = async () => {
      const recoveryPlansCollection = collection(db, 'myovolt_plans');
      const recoveryPlansSnapshot = await getDocs(recoveryPlansCollection);
      const plans = recoveryPlansSnapshot.docs.map(doc => doc.id);
      setRecoveryPlans(plans);
    };

    const fetchGroups = async () => {
      const groupsCollection = collection(db, 'groups');
      const groupsSnapshot = await getDocs(groupsCollection);
      const groupsData: Group[] = groupsSnapshot.docs.map(doc => ({
        id: doc.id,
        groupId: doc.data().groupId,
        recoveryPlan: doc.data().recoveryPlan,
        Admin: doc.data().Admin || [],
        Members: doc.data().Members || [],
      }));
      setGroups(groupsData);
    };

    fetchUsers();
    fetchRecoveryPlans();
    fetchGroups();
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      setGroupName(selectedGroup.groupId);
      setRecoveryPlan(selectedGroup.recoveryPlan);

      const adminIds = new Set(users.filter(user => selectedGroup.Admin.includes(user.email)).map(user => user.id));
      const memberIds = new Set(users.filter(user => selectedGroup.Members.includes(user.email)).map(user => user.id));

      setSelectedAdmins(adminIds);
      setSelectedMembers(memberIds);

      if (currentUserLevel === AdminLevel.USER) {
        // Only show admins and members that are part of the group for USER level
        setFilteredAdmins(users.filter(user => adminIds.has(user.id)));
        setFilteredMembers(users.filter(user => memberIds.has(user.id)));
      } else {
        // For ADMIN and SUPER_ADMIN, show group members first, then others
        setFilteredAdmins(users.sort((a, b) => Number(adminIds.has(b.id)) - Number(adminIds.has(a.id))));
        setFilteredMembers(users.sort((a, b) => Number(memberIds.has(b.id)) - Number(memberIds.has(a.id))));
      }
    }
  }, [selectedGroup, users, currentUserLevel]);

  useEffect(() => {
    if (selectedGroup === null && (currentUserLevel === AdminLevel.ADMIN || currentUserLevel === AdminLevel.USER)) {
      const currentUser = users.find(user => user.id === id);
      if (currentUser && currentUser.groupId) {
        const adminGroup = groups.find(group => group.groupId === currentUser.groupId);
        if (adminGroup) {
          setSelectedGroup(adminGroup);
        }
      }
    }
  }, [currentUserLevel, users, groups, selectedGroup]);

  const handleAdminSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setAdminSearchQuery(query);
    setFilteredAdmins(users.filter(user => (currentUserLevel !== AdminLevel.USER || selectedAdmins.has(user.id)) &&
      (user.name.toLowerCase().includes(query) || user.email.toLowerCase().includes(query))));
  };

  const handleMemberSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setMemberSearchQuery(query);
    setFilteredMembers(users.filter(user => (currentUserLevel !== AdminLevel.USER || selectedMembers.has(user.id)) &&
      (user.name.toLowerCase().includes(query) || user.email.toLowerCase().includes(query))));
  };

  const handleUserSelect = (userId: string, type: 'admin' | 'member') => {
    if (currentUserLevel === AdminLevel.USER) return;  // Prevent USER level from modifying
    const selectedSet = type === 'admin' ? selectedAdmins : selectedMembers;
    const newSelectedSet = new Set(selectedSet);
    if (newSelectedSet.has(userId)) {
      newSelectedSet.delete(userId);
    } else {
      newSelectedSet.add(userId);
      // Automatically add admin as member
      if (type === 'admin') {
        setSelectedMembers(prev => new Set(prev).add(userId));
      }
    }
    if (type === 'admin') {
      setSelectedAdmins(newSelectedSet);
    } else {
      setSelectedMembers(newSelectedSet);
    }
  };

  const handleUpdateGroup = async () => {
    if (!selectedGroup) return;

    const newAdminEmails = Array.from(selectedAdmins).map(adminId => users.find(user => user.id === adminId)?.email);
    const newMemberEmails = Array.from(new Set([...selectedAdmins, ...selectedMembers])).map(memberId => users.find(user => user.id === memberId)?.email);

    // Update the group document in Firestore
    const updatedGroup = {
      groupId: groupName,
      recoveryPlan,
      Admin: newAdminEmails,
      Members: newMemberEmails,
    };

    const groupDoc = doc(db, 'groups', selectedGroup.id);
    await updateDoc(groupDoc, updatedGroup);

    // Update the selected users' documents with the new groupName as groupId
    for (const user of users) {
      const userDoc = doc(db, 'users', user.id);

      if (selectedAdmins.has(user.id) || selectedMembers.has(user.id)) {
        // If the user is still in the group, update their groupId and recoveryPlan
        await updateDoc(userDoc, {
          groupId: groupName,
          recoveryPlan: recoveryPlan,
        });
      } else if (user.groupId === selectedGroup?.groupId) {
        // If the user is removed from the group, set groupId, recoveryPlan, and recoveryPlanDate to null
        await updateDoc(userDoc, {
          groupId: null,
          recoveryPlan: null,
          userProgressId: null,
          userSequences: {'VM1MOAABLE': 1, 'VM4MOAABLE': 1, 'VM6MOAABLE': 1}
        });
      }
    }

    onClose();
  };

  const handleLeaveGroup = async () => {
    if (!selectedGroup) return;
  
    const currentUser = users.find(user => user.id === id);
  
    if (!currentUser) return;
  
    const isUserAdmin = selectedGroup.Admin.includes(currentUser.email);
    const adminCount = selectedGroup.Admin.length;
  
    // Warning message for confirmation
    const confirmLeave = window.confirm(
      isUserAdmin && adminCount === 1
        ? 'You are the only admin in the group. You must appoint another admin or delete the group entirely. Do you want to delete the group?'
        : 'Are you sure you want to leave the group?'
    );
  
    if (!confirmLeave) return;
  
    if (isUserAdmin && adminCount === 1) {
      // If the user is the only admin, they can either delete the group or appoint another admin
      const deleteGroup = window.confirm('You are the last admin. Would you like to delete the group?');
      if (deleteGroup) {
        // Delete the group
        const groupDoc = doc(db, 'groups', selectedGroup.id);
  
        // Delete the group document from Firestore
        await deleteDoc(groupDoc);
  
        // Update all users who are in the group to have groupId set to null
        for (const memberEmail of selectedGroup.Members) {
          const user = users.find(u => u.email === memberEmail);
          if (user) {
            const userDoc = doc(db, 'users', user.id);
            await updateDoc(userDoc, { groupId: null , recoveryPlan: null, userProgressId: null,  userSequences: {'VM1MOAABLE': 1, 'VM4MOAABLE': 1, 'VM6MOAABLE': 1}});
          }
        }
  
        console.log('Group deleted and all members\' groupId set to null');
      } else {
        alert('Please appoint another admin before leaving.');
        return;
      }
    } else {
      // Remove the user from the group's members/admins list
      const updatedAdmins = selectedGroup.Admin.filter(email => email !== currentUser.email);
      const updatedMembers = selectedGroup.Members.filter(email => email !== currentUser.email);
  
      const groupDoc = doc(db, 'groups', selectedGroup.id);
      await updateDoc(groupDoc, {
        Admin: updatedAdmins,
        Members: updatedMembers,
      });
  
      // Update the user's groupId to null
      const userDoc = doc(db, 'users', currentUser.id);
      await updateDoc(userDoc, { groupId: null, recoveryPlan: null, userProgressId: null,  userSequences: {'VM1MOAABLE': 1, 'VM4MOAABLE': 1, 'VM6MOAABLE': 1}});
  
      console.log('User has left the group');
    }
  
    onClose();  // Close the modal or navigate away
    window.location.reload();  // Reload the page after leaving the group
  };

  return (
    <div className="p-6 bg-white rounded-md">
      <h2 className="text-2xl font-semibold mb-4">{currentUserLevel === AdminLevel.SUPER_ADMIN ? 'Modify Group' : groupName}</h2>

      {currentUserLevel === AdminLevel.SUPER_ADMIN ? (
        <div className="mb-4">
          <label className="block text-lg font-medium mb-2">Select Group</label>
          <select
            value={selectedGroup?.id || ''}
            onChange={(e) => setSelectedGroup(groups.find(group => group.id === e.target.value) || null)}
            className="select select-bordered w-full max-w-xs"
          >
            <option value="" disabled>Select a group</option>
            {groups.map(group => (
              <option key={group.id} value={group.id}>{group.groupId}</option>
            ))}
          </select>
        </div>
      ) : null}

      {selectedGroup && (
        <>
          {currentUserLevel !== AdminLevel.USER && (
            <div className="mb-4">
              <label className="block text-lg font-medium mb-2">Group Name</label>
              <input
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                className="input input-bordered w-full max-w-xs"
              />
            </div>
          )}
          {currentUserLevel !== AdminLevel.USER ? (
            <div className="mb-4">
              <label className="block text-lg font-medium mb-2">Recovery Plan</label>
              <select
                value={recoveryPlan}
                onChange={(e) => setRecoveryPlan(e.target.value)}
                className="select select-bordered w-full max-w-xs"
              >
                <option value="" disabled>Select a recovery plan</option>
                {recoveryPlans.map(plan => (
                  <option key={plan} value={plan}>{plan}</option>
                ))}
              </select>
            </div>
          ) : (
            <h3 className="text-xl font-medium mb-2">Recovery Plan: {recoveryPlan}</h3>
          )}
          <div className="mb-4">
            <h3 className="text-xl font-medium mb-2">Admins</h3>
            {currentUserLevel !== AdminLevel.USER && (
              <input
                type="text"
                placeholder="Search admins"
                value={adminSearchQuery}
                onChange={handleAdminSearch}
                className="input input-bordered w-full max-w-xs mb-2"
              />
            )}
            <div className="max-h-60 overflow-y-scroll border border-gray-300 rounded-md p-2">
              {filteredAdmins.map((user) => (
                <div key={user.id} className="flex items-center justify-between p-2 border-b border-gray-200">
                  <div className="text-sm">
                    <p>{user.name}</p>
                    <p className="text-gray-600">{user.email}</p>
                  </div>
                  {currentUserLevel !== AdminLevel.USER && (
                    <input
                      type="checkbox"
                      checked={selectedAdmins.has(user.id)}
                      onChange={() => handleUserSelect(user.id, 'admin')}
                      className="checkbox checkbox-primary"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <h3 className="text-xl font-medium mb-2">Members</h3>
            {currentUserLevel !== AdminLevel.USER && (
              <input
                type="text"
                placeholder="Search members"
                value={memberSearchQuery}
                onChange={handleMemberSearch}
                className="input input-bordered w-full max-w-xs mb-2"
              />
            )}
            <div className="max-h-60 overflow-y-scroll border border-gray-300 rounded-md p-2">
              {filteredMembers.map((user) => (
                <div key={user.id} className="flex items-center justify-between p-2 border-b border-gray-200">
                  <div className="text-sm">
                    <p>{user.name}</p>
                    <p className="text-gray-600">{user.email}</p>
                  </div>
                  {currentUserLevel !== AdminLevel.USER && (
                    <input
                      type="checkbox"
                      checked={selectedMembers.has(user.id)}
                      onChange={() => handleUserSelect(user.id, 'member')}
                      className="checkbox checkbox-primary"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          {currentUserLevel !== AdminLevel.USER && (
            <div className="mt-6">
              <button className="btn mx-2 p-2 w-40" onClick={handleUpdateGroup}>
                Update Group
              </button>
            </div>
          )}
          <div className="mt-6">
              <button className="btn mx-2 p-2 w-40" onClick={handleLeaveGroup}>
                Leave Group
              </button>
            </div>
        </>
      )}
    </div>
  );
};

export default ModifyGroup;