import React, { useState, useEffect } from 'react';
import { LineChart, Area, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart, Bar } from 'recharts';
import { DateTime } from 'luxon';
import { exportMetricsToCsv } from "../../utils";

// Define types for your data
interface DataItem {
  date: string;
  treatment: { total: number };
  mobility: { average: number };
  pain: { average: number };
  recovery: number;
}

interface MetricsGraphProps {
  userData: DataItem[];
  groupData: DataItem[];
  userName: string | undefined;
  groupId: string | null | undefined;
  userEmail: string | null | undefined;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
}

interface WeeklyData {
  treatment: number;
  mobility: number;
  pain: number;
  recovery: number;
  count: number;
}

// Custom Tooltip Component
const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc', fontSize: '12px' }}>
        <p>Date: {data.date}</p>
        <p>Treatment: {data.treatmentTotal} minutes</p>
        <p>Mobility: {Math.round(data.mobilityAverage)}</p>
        <p>Recovery: {Math.round(data.recovery)}</p>
        <p>Pain: {Math.round(data.painAverage)}</p>
      </div>
    );
  }

  return null;
};

const initialLineVisibility = [true, true, true, true];
const timeRanges = [
  { label: '7 Days', value: 7 },
  { label: '1 Month', value: 30 },
  { label: '3 Months', value: 90 },
];

// Function to generate fake data
const generateFakeData = (days: number): DataItem[] => {
  const fakeData: DataItem[] = [];
  for (let i = 0; i < days; i++) {
    const date = DateTime.now().minus({ days: days - i }).toFormat('dd/MM/yyyy');
    fakeData.push({
      date,
      treatment: { total: Math.floor(Math.random() * 100) },
      mobility: { average: Math.floor(Math.random() * 10) + 1 },
      pain: { average: Math.floor(Math.random() * 10) + 1 },
      recovery: Math.floor(Math.random() * 10) + 1,
    });
  }
  return fakeData;
};

const MetricsGraph: React.FC<MetricsGraphProps> = ({ userData, groupData, userName, groupId, userEmail}) => {
  const [lineVisibility, setLineVisibility] = useState<boolean[]>(initialLineVisibility);
  const [daysShown, setDaysShown] = useState<number>(7);
  const [filteredData, setFilteredData] = useState<DataItem[]>([]);
  const [useFakeData, setUseFakeData] = useState<boolean>(false);
  const [useGroupData, setUseGroupData] = useState<boolean>(false); // New state for toggling between group and user data

  const toggleLineVisibility = (index: number) => {
    const newVisibility = [...lineVisibility];
    newVisibility[index] = !newVisibility[index];
    setLineVisibility(newVisibility);
  };

  const handleTimeRangeChange = (days: number) => {
    setDaysShown(days);
  };

  useEffect(() => {
    const filterData = (days: number, dataSource: DataItem[]) => {
      const now = DateTime.now();
      return dataSource.filter(item => {
        const itemDate = DateTime.fromFormat(item.date, 'dd/MM/yyyy');
        return itemDate >= now.minus({ days });
      });
    };

    const sourceData = useFakeData
      ? generateFakeData(90)
      : useGroupData
      ? groupData
      : userData; // Switch between user and group data based on toggle

    const filtered = filterData(daysShown, sourceData);
    setFilteredData(filtered);
  }, [userData, groupData, daysShown, useFakeData, useGroupData]);

  const transformedData = filteredData.map(item => ({
    ...item,
    treatmentTotal: item.treatment.total,
    mobilityAverage: item.mobility.average,
    painAverage: item.pain.average,
    timeStamp: DateTime.fromFormat(item.date, 'dd/MM/yyyy').toMillis(),
  }));

  const processTicks = (days: number) => {
    if (days === 7) {
      return Array.from({ length: 7 }).map((_, i) => DateTime.now().minus({ days: 7 - i - 1 }).startOf('day').toMillis());
    } else if (days === 30) {
      return Array.from({ length: 31 }).map((_, i) => DateTime.now().minus({ days: 31 - i - 1 }).startOf('day').toMillis());
    } else if (days === 90) {
      return Array.from({ length: 13 }).map((_, i) => DateTime.now().minus({ weeks: 13 - i - 1 }).startOf('week').toMillis());
    }
    return [];
  };

  const domainStart_m = DateTime.now().minus({ days: daysShown }).startOf('day').toMillis();
  const domainEnd_m = DateTime.now().startOf('day').toMillis();
  const ticks = processTicks(daysShown);
  const formatTick = (tick: number) => {
    if (daysShown === 7) return DateTime.fromMillis(tick).toFormat('EEE');
    if (daysShown === 30) return DateTime.fromMillis(tick).toFormat('dd');
    if (daysShown === 90) return DateTime.fromMillis(tick).toFormat('MMM dd');
    return DateTime.fromMillis(tick).toFormat('dd/MM/yyyy');
  };

  return (
    <div className="card">
      <h2 className="text-xl font-semibold p-4 text-center">
        Trends for {useGroupData ? groupId : userName}
      </h2>
      
      <div className="flex mb-4" style={{paddingLeft:'350px'}}>
        <span>User Data</span>
        <label className="switch mx-4">
          {groupId !== null && (
            <input 
              type="checkbox" 
              checked={useGroupData} 
              onChange={() => setUseGroupData(!useGroupData)} 
            />
          )}
          <span className="slider round"></span>
        </label>
        <span>Group Data</span>
        {useGroupData ? (
      <span className="text-blue-500 cursor-pointer underline" style={{fontSize: '12px', paddingLeft: '20px'}}
        onClick={() => { exportMetricsToCsv("Group Metrics.csv", groupData as any[], groupId!) }} // Export all treatment data
      >
        Export all to CSV ({groupId})
      </span>) : (
        <span className="text-blue-500 cursor-pointer underline " style={{fontSize: '12px', paddingLeft:'20px'}}
        onClick={() => { exportMetricsToCsv("User Metrics.csv", userData as any[], userEmail!) }} // Export all treatment data
      >
        Export all to CSV ({userEmail})
      </span>
      )}
      </div>
      <div className="flex flex-row">
        <div className="flex-grow">
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              data={transformedData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                interval={0}
                ticks={ticks}
                tickFormatter={formatTick}
                dataKey="timeStamp"
                type="number"
                domain={[domainStart_m, domainEnd_m]}
                scale="time"
                style={{ fontSize: '12px' }}
              />
              <YAxis
                domain={[0, 10]}
                style={{ fontSize: '12px' }}
                ticks={[0, 10]}
                axisLine={{ stroke: '#FFFFFF' }}
                tickLine={{ stroke: '#FFFFFF' }}
                tick={{ fill: '#000000' }}
              />
              <YAxis
                yAxisId="t"
                domain={useGroupData ? ([0, 1000]) : [0, 100]}
                ticks={[]}
                axisLine={{ stroke: '#FFFFFF' }}
                tickLine={{ stroke: '#FFFFFF' }}
                tick={{ fill: '#FFFFFF' }}
              />
              {lineVisibility[0] && <Bar yAxisId="t" dataKey="treatmentTotal" barSize={8} fill="#9747ff" isAnimationActive={false}/>}
              {lineVisibility[1] && (
                <>
                  <Area type="monotone" dataKey="mobilityAverage" stroke="#56c1ca" fill="rgba(86, 193, 202, 0)" dot={{ r: 0 }} isAnimationActive={false} />
                  <Line type="monotone" strokeWidth="2" dataKey="mobilityAverage" stroke="#56c1ca" dot={{ r: 0 }} isAnimationActive={false} />
                </>
              )}
              {lineVisibility[2] && (
                <>
                  <Area type="monotone" dataKey="recovery" stroke="#73E38C" fill="rgba(115, 227, 140, 0.1)" dot={{ r: 0 }} isAnimationActive={false} />
                  <Line type="monotone" strokeWidth="2" dataKey="recovery" stroke="#73E38C" dot={{ r: 0 }} isAnimationActive={false}/>
                </>
              )}
              {lineVisibility[3] && (
                <>
                  <Area type="monotone" dataKey="painAverage" stroke="#F60E38" fill="rgba(255, 14, 56, 0.1)" dot={{ r: 0 }} isAnimationActive={false} />
                  <Line type="monotone" strokeWidth="2" dataKey="painAverage" stroke="#F60E38" dot={{ r: 0 }} isAnimationActive={false} />
                </>
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="flex justify-center my-4">
        {timeRanges.map((range) => (
          <button
            key={range.value}
            className={`btn mx-2 p-2 w-40  ${daysShown === range.value ? 'btn-active' : 'btn-inactive'}`}
            onClick={() => handleTimeRangeChange(range.value)}
          >
            {range.label}
          </button>
        ))}
      </div>
      <ul className="menu menu-vertical lg:menu-horizontal bg-base-100 rounded-box justify-center">
        <li><a className="bg-base-100 btn" style={{ color: '#9747ff', opacity: lineVisibility[0] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(0)}>Treatment</a></li>
        <li><a className="bg-base-100 btn" style={{ color: '#56c1ca', opacity: lineVisibility[1] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(1)}>Mobility</a></li>
        <li><a className="bg-base-100 btn" style={{ color: '#73E38C', opacity: lineVisibility[2] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(2)}>Recovery</a></li>
        <li><a className="bg-base-100 btn" style={{ color: '#F60E38', opacity: lineVisibility[3] ? '1' : '0.2' }} onClick={() => toggleLineVisibility(3)}>Pain</a></li>
      </ul>
    </div>
  );
};

export default MetricsGraph;