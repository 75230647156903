import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, addDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Import Firestore instance from your firebase configuration

interface User {
  id: string;
  name: string;
  email: string;
  groupId: string | null;
}

const NewGroup: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [groupName, setGroupName] = useState('');
  const [recoveryPlan, setRecoveryPlan] = useState('');
  const [recoveryPlans, setRecoveryPlans] = useState<string[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [filteredAdmins, setFilteredAdmins] = useState<User[]>([]);
  const [filteredMembers, setFilteredMembers] = useState<User[]>([]);
  const [selectedAdmins, setSelectedAdmins] = useState<Set<string>>(new Set());
  const [selectedMembers, setSelectedMembers] = useState<Set<string>>(new Set());
  const [adminSearchQuery, setAdminSearchQuery] = useState<string>('');
  const [memberSearchQuery, setMemberSearchQuery] = useState<string>('');

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      const usersData: User[] = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        email: doc.data().email,
        groupId: doc.data().groupId || null,
      }));

      setUsers(usersData);
      setFilteredAdmins(usersData);
      setFilteredMembers(usersData);
    };

    const fetchRecoveryPlans = async () => {
      const recoveryPlansCollection = collection(db, 'myovolt_plans');
      const recoveryPlansSnapshot = await getDocs(recoveryPlansCollection);
      const plans = recoveryPlansSnapshot.docs.map(doc => doc.id);
      setRecoveryPlans(plans);
    };

    fetchUsers();
    fetchRecoveryPlans();
  }, []);

  const handleAdminSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setAdminSearchQuery(query);
    setFilteredAdmins(users.filter(user => user.name.toLowerCase().includes(query) || user.email.toLowerCase().includes(query)));
  };

  const handleMemberSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setMemberSearchQuery(query);
    setFilteredMembers(users.filter(user => user.name.toLowerCase().includes(query) || user.email.toLowerCase().includes(query)));
  };

  const handleUserSelect = (userId: string, type: 'admin' | 'member') => {
    const selectedSet = type === 'admin' ? selectedAdmins : selectedMembers;
    const newSelectedSet = new Set(selectedSet);
    if (newSelectedSet.has(userId)) {
      newSelectedSet.delete(userId);
    } else {
      newSelectedSet.add(userId);
      // Automatically add admin as member
      if (type === 'admin') {
        setSelectedMembers(prev => new Set(prev).add(userId));
      }
    }
    if (type === 'admin') {
      setSelectedAdmins(newSelectedSet);
    } else {
      setSelectedMembers(newSelectedSet);
    }
  };

  const handleCreateGroup = async () => {
    // Create the group document in Firestore
    const newGroup = {
      groupId: groupName,
      recoveryPlan,
      Admin: Array.from(selectedAdmins).map(adminId => users.find(user => user.id === adminId)?.email),
      Members: Array.from(new Set([...selectedAdmins, ...selectedMembers])).map(memberId => users.find(user => user.id === memberId)?.email),
    };

    await addDoc(collection(db, 'groups'), newGroup);

    // Update the selected users' documents with the groupName as groupId
    for (const userId of Array.from(new Set([...selectedAdmins, ...selectedMembers]))) {
      const userDoc = doc(db, 'users', userId);
      await updateDoc(userDoc, { groupId: groupName, recoveryPlan:recoveryPlan });
    }

    onClose();
    window.location.reload();

  };

  return (
    <div className="p-6 bg-white rounded-md">
      <h2 className="text-2xl font-semibold mb-4">New Group</h2>
      <div className="mb-4">
        <label className="block text-lg font-medium mb-2">Group Name</label>
        <input
          type="text"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          className="input input-bordered w-full max-w-xs"
        />
      </div>
      <div className="mb-4">
        <label className="block text-lg font-medium mb-2">Recovery Plan</label>
        <select
          value={recoveryPlan}
          onChange={(e) => setRecoveryPlan(e.target.value)}
          className="select select-bordered w-full max-w-xs"
        >
          <option value="" disabled>Select a recovery plan</option>
          {recoveryPlans.map(plan => (
            <option key={plan} value={plan}>{plan}</option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-medium mb-2">Assign Admins</h3>
        <input
          type="text"
          placeholder="Search admins"
          value={adminSearchQuery}
          onChange={handleAdminSearch}
          className="input input-bordered w-full max-w-xs mb-2"
        />
        <div className="max-h-60 overflow-y-scroll border border-gray-300 rounded-md p-2">
          {filteredAdmins
            .filter(user => !user.groupId) // Only show users without a groupId
            .map((user) => (
            <div key={user.id} className="flex items-center justify-between p-2 border-b border-gray-200">
              <div className="text-sm">
                <p>{user.name}</p>
                <p className="text-gray-600">{user.email}</p>
              </div>
              <input
                type="checkbox"
                checked={selectedAdmins.has(user.id)}
                onChange={() => handleUserSelect(user.id, 'admin')}
                className="checkbox checkbox-primary"
                disabled={!!user.groupId} // Disable if the user already has a groupId
              />
            </div>
          ))}
        </div>
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-medium mb-2">Assign Members</h3>
        <input
          type="text"
          placeholder="Search members"
          value={memberSearchQuery}
          onChange={handleMemberSearch}
          className="input input-bordered w-full max-w-xs mb-2"
        />
        <div className="max-h-60 overflow-y-scroll border border-gray-300 rounded-md p-2">
          {filteredMembers
            .filter(user => !user.groupId) // Only show users without a groupId
            .map((user) => (
            <div key={user.id} className="flex items-center justify-between p-2 border-b border-gray-200">
              <div className="text-sm">
                <p>{user.name}</p>
                <p className="text-gray-600">{user.email}</p>
              </div>
              <input
                type="checkbox"
                checked={selectedMembers.has(user.id)}
                onChange={() => handleUserSelect(user.id, 'member')}
                className="checkbox checkbox-primary"
                disabled={!!user.groupId} // Disable if the user already has a groupId
              />
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6">
        <button className="btn mx-2 p-2 w-40" onClick={handleCreateGroup}>
          Create Group
        </button>
      </div>
    </div>
  );
};

export default NewGroup;